<template>
  <v-menu
    ref="menu"
    v-model="menu"
    :close-on-content-click="false"
    transition="scale-transition"
    offset-y
    min-width="auto"
  >
    <template v-slot:activator="{ on, attrs }">
      <v-text-field
        v-model="value"
        :label="label"
        prepend-icon="mdi-calendar"
        v-bind="attrs"
        v-on="on"
      />
    </template>
    <v-date-picker
      ref="picker"
      v-model="value"
      header-color="primary"
      color="secondary"
      :max="max"
      :min="min"
      @change="save"
    />
  </v-menu>
</template>

<script>
  export default {
    name: 'DatePicker',
    props: {
      value: {
        type: String,
        default: null,
      },
      label: {
        type: String,
        default: 'Date Picker',
      },
      type: {
        type: String,
        default: 'date',
      },
    },
    data () {
      return {
        date: null,
        menu: false,
      }
    },
    computed: {
      max () {
        let value = null
        if (this.type === 'birthday') {
          value = new Date().toISOString().substr(0, 10)
        }
        return value
      },
      min () {
        let value = null
        if (this.type === 'birthday') {
          value = '1950-01-01'
        }
        return value
      },
    },
    watch: {
      menu (val) {
        if (val) {
          let activePicker = 'DATE'
          if (this.type === 'birthday') {
            activePicker = 'YEAR'
          }
          setTimeout(() => (this.$refs.picker.activePicker = activePicker))
        }
      },
    },
    methods: {
      save (date) {
        this.$refs.menu.save(date)
        this.$emit('input', date)
      },
    },
  }
</script>
